import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NDISSupportItemObject } from '@core/models/ndisSupportItemObject';
import dayjs from 'dayjs';

import round from 'lodash-es/round';
import sumBy from 'lodash-es/sumBy';
import filter from 'lodash-es/filter';
import each from 'lodash-es/each';
import find from 'lodash-es/find';
import clone from 'lodash-es/clone';
import map from 'lodash-es/map';
import startsWith from 'lodash-es/startsWith';
import split from 'lodash-es/split';
import flatMap from 'lodash-es/flatMap';
import join from 'lodash-es/join';
import orderBy from 'lodash-es/orderBy';
import minBy from 'lodash-es/minBy';
import maxBy from 'lodash-es/maxBy';
import cloneDeep from 'lodash-es/cloneDeep';
import first from 'lodash-es/first';
import last from 'lodash-es/last';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  // job.jobType.awardSection -> then employee.awardLineItems <- match these to get baseRate
  // not all set at the moment
  constructor() { }

  private superAnnuationRate = .11;
  private workCoverRate = .01999; // 1.999 per $100 in wages   //.02048;
  private portableLongServiceLeaveRate = 0.0135;    // 1.35%
  private annualLeaveRate = 0.0833;  

  goTo(router, uri: any, externalLink: any = false) {
		if (externalLink) {
			let url = router.createUrlTree([uri], { queryParams: {} });
			window.open(url.toString(), '_blank');
		} else {
			router.navigate([uri]);
		}
	}

  ndisNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const ndis = control.value;
      if (!ndis) {
        return null; // Return null to indicate no validation error when the field is empty
      }

      if (this.isNdisNumberValid(ndis)) {
        return null; // Return null to indicate no validation error when the field is valid
      }
      return { 'ndisNumber': true };  // Return an error object if validation fails
    }
  }

  isNdisNumberValid(ndis: any) {
    if (!ndis) return false;

    ndis = this.removeUnwantedSpaceDashDotChars(ndis);

    if (ndis.length !== 9) return false;

    // Check if the cleaned NDIS matches the pattern
    if (!/^4\d{8}$/.test(ndis)) {
      return false;
    }

    return true; // If all conditions are met, NDIS is valid
  }

  abnValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const abn = control.value;
      
      if (!abn) {
        return null; // Return null to indicate no validation error when the field is empty
      }

      if (this.isAbnValid(abn)) {     
        //console.log('abn valid true')  
        return null       
      }
      return { 'abn': true };  // Return an error object if validation fails
    }
  }

  isAbnValid(abn: any) {

    if (!abn) return false;

    abn = this.removeUnwantedSpaceDashDotChars(abn);

    // console.log('abn', abn)
    // console.log('abn.length', abn.length)

    if (abn.length !== 11) return false;

    // Check if the cleaned ABN matches the pattern
    // if (!/^\d{11}$/.test(abn)) {
    //   return false; 
    // }

    // Subtract 1 from the first digit of the ABN
    const modifiedAbn = (parseInt(abn[0], 10) - 1).toString() + abn.substring(1);
    //console.log('modifiedAbn', modifiedAbn)

    const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
    let sum = 0;

    for (let i = 0; i < 11; i++) {
      sum += parseInt(modifiedAbn[i], 10) * weights[i]; // Calculate sum of products
    }

    // console.log('sum1', sum  )
    // console.log('sum', sum % 89 )
    return sum % 89 === 0; // Check remainder as BigInt
  }

  isAcnValid(acn: string): boolean {
    if (!acn) return false;
  
    acn = this.removeUnwantedSpaceDashDotChars(acn);
  
    if (acn.length !== 9) return false; // ACN must have 9 digits
  
    // Check if the cleaned ACN consists of digits only
    if (!/^\d+$/.test(acn)) {
      return false;
    }
  
    return true; // If all conditions are met, ACN is valid
  }

  getWorkDayPeriod(startDate: any, endDate: any) {
    // console.log('startDate', startDate);
    // console.log('endDate', endDate);

    let start = dayjs(startDate, 'YYYY-MM-DDThh:mm:ss').format('YYYY-MM-DD')
    let end = dayjs(endDate, 'YYYY-MM-DDThh:mm:ss').format('YYYY-MM-DD')

    // console.log('start', start);
    // console.log('end', end);

    const startDateDay = startDate ? dayjs(start).day() : null;
    const endDateDay = endDate ? dayjs(end).day() : null;

    const startTime = parseInt(dayjs(startDate, 'YYYY-MM-DDThh:mm:ss').format('HHmm'));
    const endTime = parseInt(dayjs(endDate, 'YYYY-MM-DDThh:mm:ss').format('HHmm'));

    // console.log('startTime', startTime);
    // console.log('endTime', endTime);

    // console.log('startDateDay', startDateDay);
    // console.log('endDateDay', endDateDay);

    // return N/A if time goes over new day
    if (startDateDay !== endDateDay) return 'N/A';

    if (startDateDay === 6 && endDateDay === 6) { return 'SATURDAY'; } else
      if (startDateDay === 0 && endDateDay === 0) { return 'SUNDAY'; } else {
        if (startTime >= 600 && endTime <= 2000) { return 'DAYTIME'; } else
          if (startTime >= 600 && endTime <= 2200) { return 'EVENING'; } else
            if (startTime >= 2200 || startTime < 600 || endTime > 2200 || endTime <= 600) { return 'NIGHT'; } else
              return 'N/A';
      }
  }

  getInitials(name: any) {
    if (!name) return "*";

    var parts = name.split(' ')
    var initials = ''
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== '' && i < 2) {
        initials += parts[i][0]
      }
    }
    return initials.toUpperCase();
  }

  getInitials2(name: any) {
    return name.split(" ").map((n) => n[0]).join("");
  }

  getScheduleMarginEstimates(schedule: any) { }

  getJobMarginEstimates(job: any) {
    job.summary = [];

    const hourlyJobCharges = this.getHourlyJobCharges(job);
    //console.log('hourlyJobCharges', hourlyJobCharges)
    const totalChargeHours = round(sumBy(hourlyJobCharges, c => c.quantity / (c.clientRatio > 0 ? c.clientRatio : 1)), 2);
    //console.log('totalChargeHours', totalChargeHours)
    const totalChargeDollars = sumBy(hourlyJobCharges, (a: any) => a.quantity * a.unit);
    const staffSchedules = filter(job.schedules, s => s.taskTypeName !== 'M');
    const totalStaffHours = Number(sumBy(staffSchedules, (sch: any) =>
      parseFloat(sch.hours) * (sch.employees ? sch.employees.length : 1)
    ).toFixed(2));
    const totalStaffHoursApproved = Number(sumBy(job.timeEntries, (te: any) =>
      parseFloat(te.approvedHours)
    ).toFixed(2));

    // TODO use timesheetlineitems to get accurate wage  
    const day = dayjs(job.scheduled?.from).day();

    // TODO work with Award to get more accurate baseRate
    let baseRate = this.getBaseRateEstimate(job, day);
    //console.log('baseRate', baseRate)

    // we have been passed job so we have to check all schedules for staff hours and rates
    map(staffSchedules, sch => {
      let awardSectionId = sch.jobType?.awardSection?.id
      map(sch.employees, emp => {
        // get time entries for this employee
        let timeEntries = find(job.timeEntries, te => te.employeeId == emp.id)
        emp.staffHours = Number(sch.hours);  // only works if schedule has had hours calculated first
        emp.staffHoursApproved = Number(sumBy(timeEntries, (te: any) => parseFloat(te.approvedHours)));

        // get the award level and paypoint for the staff, check CAS or FULL or PART  
        let awardLineItemFind = find(emp.awardLineItems, li => li.awardSectionId == awardSectionId)
        emp.awardLineItemFind = awardLineItemFind
        emp.estimateBaseWage = awardLineItemFind ? awardLineItemFind.baseRate : baseRate

        //console.log('emp', emp)

        // check the rate for workDayPeriod inclusive of super etc
        emp.rateEstimate = this.getRateEstimate(sch.start, sch.end, emp.estimateBaseWage, awardLineItemFind)
        emp.estimateWages = (emp.staffHoursApproved > 0 ? emp.staffHoursApproved : emp.staffHours) * emp.rateEstimate;

        // console.log('rateEstimate', emp.rateEstimate)
        // console.log('emp.staffHours', emp.staffHours)
        // console.log('emp.estimateWages', emp.estimateWages)
      })
    });

    const estimateWages = sumBy(staffSchedules, sch => sumBy(sch.employees, (emp: any) => emp.estimateWages))
    //console.log('estimateWages', estimateWages);   

    // old estimateMargin
    //const estimateMargin = (totalChargeDollars - estimateWages) / estimateWages;

    // new estimateMargin

    const estimateSuperAnnuation = estimateWages * 0.11;
    const estimateLeave = estimateWages * 0.025;
    //console.log('estimateWages',estimateWages);
    //console.log('estimateSuperAnnuation',estimateSuperAnnuation);
    //console.log('estimateLeave',estimateLeave);
    //console.log('totalChargeDollars', totalChargeDollars)
    const estimateMargin = (estimateWages + estimateLeave) / (totalChargeDollars)
    //console.log('totalChargeDollars',totalChargeDollars);

    let totalScheduledHours = 0;
    map(job.schedules, sch => {
      totalScheduledHours += parseFloat(sch.hours) * (sch.employees ? sch.employees.length : 1);
    });

    const data = {
      staffSchedules: staffSchedules,
      estimateWages: estimateWages,
      estimateMargin: estimateMargin,
      totalStaffHours: totalStaffHours,
      totalChargeHours: totalChargeHours,
      totalChargeDollars: totalChargeDollars,
      totalScheduledHours: totalScheduledHours,
      totalStaffHoursApproved: totalStaffHoursApproved
    }

    return data;
  }

  publicHolidayCheck(publicHolidays: any, schDate: any) {
    // needs to be just == because date type doesnt match
    const holidays = filter(publicHolidays, ph => ph.Date == schDate);
    return holidays;
  }

  getHourlyJobCharges(job: any) {
    const hourlyJobCharges = filter(job.charges, a => (a.details === null || (a.details?.includes('Mileage') < 1 &&
      a.details?.includes('Transport') < 1))
      && (a.itemCode === null || (a.itemCode?.includes('Transport') < 1 &&
        a.itemCode?.includes('Mileage') < 1 &&
        a.itemCode.includes('non-labour costs') < 1)));

    return hourlyJobCharges;
  }

  getKmsJobCharges(job: any) {
    const hourlyJobCharges = filter(job.charges, a =>
      a.details?.includes('Mileage') ||
      a.details?.includes('Transport') ||
      a.itemCode?.includes('Mileage') ||
      a.itemCode?.includes('Transport') ||
      a.details?.includes('non-labour costs') ||
      a.itemCode?.includes('non-labour costs')
    );

    return hourlyJobCharges;
  }

  getBaseRateEstimate(job: any, day: any) {
    // rates
    // TODO loop through all and mix-match
    const jobType = job.schedules && job.schedules.length && job.schedules[0].jobType ? job.schedules[0].jobType.name : null;
    let schedules = job.schedules;
    //console.log('jobType', jobType);

    // loop through schedules to get baseRate for Award
    // but we have to know whether its L1P1 or something else so we need staff award lines
    // job.jobType.awardSection -> then employee.awardLineItems <- match these to get baseRate
    // not all set at the moment
    map(schedules, (s: any) => {
      let employees = s.employees;
      map(employees, e => {
        //console.log('employee', e);
        let award = e.awardLineItems;
        // currently no awardLineItems are coming from db
      })
    });

    let baseRate = jobType ? jobType.includes('Gardening') ? 39 : 40.26 : 0;
    //console.log('baseRate1', baseRate);
    // switch (day) {
    //   case 6: baseRate = baseRate * .9 * 1.5; break;   // .9 because the 1.5 only covers the base
    //   case 0: baseRate = baseRate * .9 * 2; break;
    //   default: baseRate = baseRate;
    // }

    // // TODO Public Holiday
    // // check the state base of employee work v state of job, some sort of alert

    // // console.log('job.scheduledFrom', job.scheduledFrom);
    // //  console.log('day', day);
    // //  console.log('baseRate2', baseRate);
    // const workCover = 1.02048;
    // const superannuation = 1.105;

    // TODO
    //rate = rate * superannuation * workCover;

    return baseRate;
  }

  getRateEstimate(startDate: any, endDate: any, baseRate: any, awardLineItem: any = null) {
    let workDayPeriod = this.getWorkDayPeriod(startDate, endDate)
    //console.log('workDayPeriod', workDayPeriod);
    // console.log('baseRate0', baseRate)
    // console.log('awardLineItem', awardLineItem);

    if (awardLineItem && awardLineItem?.name == 'Social and Community Services') {
      switch (workDayPeriod) {
        case 'PH': baseRate = baseRate * 2.2; break;
        case 'SATURDAY': baseRate = baseRate * 1.4; break;
        case 'SUNDAY': baseRate = baseRate * 1.8; break;
        case 'EVENING': baseRate = baseRate * 1.1; break;
        case 'NIGHT': baseRate = baseRate * 1.15; break;
        case 'DAYTIME': baseRate = baseRate; break;
      }
    } else {
      switch (workDayPeriod) {
        case 'PH': baseRate = baseRate * 2.5; break;
        case 'SATURDAY': baseRate = baseRate * 1.5; break;
        case 'SUNDAY': baseRate = baseRate * 2; break;
        case 'EVENING': baseRate = baseRate * 1.1215; break;
        case 'NIGHT': baseRate = baseRate * 1.15; break;
        case 'DAYTIME': baseRate = baseRate; break;
      }
    }

    //console.log('baseRate1', baseRate)

    // is casual saturday 175% and perm 150% ?
    // sunday causal 225% and perm 200% ?
    // ph 250%, 275% ?

    
   
    // add workcover and super to the rate
    let workCoverAdd = baseRate * this.workCoverRate;
    let superannuationAdd = baseRate * this.superAnnuationRate;

    // do we add super for evening, night, weekends etc?
    // workcover ?

    // if casual do not add anything forleave



    // portable long service leave
    this.portableLongServiceLeaveRate



    // annual leave



    baseRate = baseRate + workCoverAdd + superannuationAdd;
    // console.log('workCoverAdd', workCoverAdd)
    // console.log('superannuationAdd', superannuationAdd)
    // console.log('baseRate2', baseRate)

    return baseRate;
  }

  calcHours(item: any, setHours: boolean = true) {
    item.nextDay = false;
    let start = dayjs(`${!isNaN(item.startDate) ? dayjs(item.startDate).format('DD/MM/YYYY') : item.startDate} ${this.extractTime(item.startTime)}`, 'DD/MM/YYYY HH:mm');
    let end = dayjs(`${!isNaN(item.startDate) ? dayjs(item.startDate).format('DD/MM/YYYY') : item.startDate} ${this.extractTime(item.endTime)}`, 'DD/MM/YYYY HH:mm');

    let minutes = end.diff(start, 'minute');
    if (minutes < 0) {
      end = end.add(1, 'day');
      minutes = end.diff(start, 'minute');
      item.nextDay = true;
    }
    item.startDate = start.format('DD/MM/YYYY');
    item.endDate = end.format('DD/MM/YYYY');
    if (setHours) { item.hours = (end.diff(start, 'minutes') / 60).toFixed(2); }
  }

  getSupportItemNumbers(guides: any, serviceDate: any) {
    const inEffectPriceGuide = first(orderBy(filter(guides, guide => {
      return dayjs(guide.startDate, 'YYYY-MM-DD').isBefore(dayjs(serviceDate));
    }), ['startDate'], 'desc'));

    if (inEffectPriceGuide) {
      map(inEffectPriceGuide.priceGuideItems, r => {
        r.searchField = `${r.code} ${r.name}`;
        r.supportItemNumber = r.code;
        r.supportItemName = r.name;
        return r;
      });

      inEffectPriceGuide.records = inEffectPriceGuide.priceGuideItems;
      return inEffectPriceGuide;
    }
  }

  async mapRosterData(jobs) {
    map(jobs, job => {
      job.scheduledFrom = job.schedules.length ? minBy(job.schedules, (a: any) => a.start).start : null;
      job.scheduledFrom = job.scheduledFrom ? job.scheduledFrom.split('+')[0] : null;
      job.scheduledTo = job.schedules.length ? maxBy(job.schedules, (a: any) => a.end).end : null;
      job.scheduledTo = job.scheduledTo ? job.scheduledTo.split('+')[0] : null;
      job.scheduledSameDate = dayjs(job.scheduledFrom).isSame(dayjs(job.scheduledTo), 'date') ? true : false;
      job.scheduledSpanDays = dayjs(job.scheduledTo).diff(job.scheduledFrom, 'day', false);

      map(job.schedules, sch => { sch.employeeList = join(map(sch.employees, 'fullName'), (',')); });
    });

    const rosters = orderBy(jobs, a => a.scheduledFrom, 'desc');

    return rosters;
  }

  getExpectedRateItem(inEffectPriceGuide: any, supportItemNumber: any) {
    let expectedRateItem: any = {};
    if (inEffectPriceGuide) {
      expectedRateItem.priceGuide = {}; // inEffectPriceGuide;
      expectedRateItem.priceGuide.id = inEffectPriceGuide.id;
      expectedRateItem.priceGuide.effectiveFrom = dayjs(inEffectPriceGuide.startDate).format('YYYY-MM-DD');
      let supportItem = find(inEffectPriceGuide.records, a => a.code === supportItemNumber);
      if (supportItem) {
        expectedRateItem.priceDefault = supportItem.baseRate;
        expectedRateItem.supportItemName = supportItem.name;
        expectedRateItem.supportItemNumber = supportItem.supportItemNumber;
        //c.expectedRateItem.unit = supportItem.unit 'H';
      };
    }

    return expectedRateItem;
  }

  getJobScheduleDatesPresentation(job: any) {
    let scheduled: any = {};

    if (job.schedules) {
      scheduled.from = job.schedules.length > 0 ? minBy(job.schedules, (a: any) => a.start).start : null;
      scheduled.from = scheduled.from ? scheduled.from.split('+')[0] : null;
      scheduled.to = job.schedules.length > 0 ? maxBy(job.schedules, (a: any) => a.end).end : null;
      scheduled.to = scheduled.to ? scheduled.to.split('+')[0] : null;
      scheduled.sameDate = dayjs(scheduled.from).isSame(dayjs(scheduled.to), 'date') ? true : false;
      scheduled.spanDays = dayjs(scheduled.to).diff(scheduled.from, 'day', false);
      scheduled.messageSends = map(filter(job.schedules, sch => sch.messageSends.length), f => { return f.messageSends[0] });
      //console.log('scheduled', scheduled);
    }
    return scheduled;
  }

  getSMSPhone(phoneNumber: string) {

    phoneNumber = phoneNumber.replace(/\s/g, '');

    //console.log('phoneNumber', phoneNumber);     

    var newArray = phoneNumber.match
      (/^(61|)?(\d{4})(\d{3})(\d{3})$/);

    //console.log('newArray', newArray);                

    var intlCountryCode = '+61'; // (newArray[1]?'+61':'');

    var internationalNumber = intlCountryCode
      + newArray[2] + newArray[3]
      + newArray[4];

    return internationalNumber;
  }

  extractTime(st: any) {
    if (st.length === 8) { return st.substring(0, 5); }
    if (st.length === 12) { return st.substring(4, 9); }
    return st;
  }

  calcHoursAndMinutes(value: any) {
    let result;
    value = value.replace('(', '').replace(')', '').replace('m', '').replace('h', '');
    if (value) {
      let hours = parseInt(value.split(':')[0], 10);
      let minutes = parseInt(value.split(':')[1], 10);

      if (minutes > 60) {
        hours++;
        minutes = minutes - 60;
      }

      if (minutes > 0) {
        result = hours + minutes / 60;
      } else {
        result = hours;
      }
      return Math.round(result * 1e2) / 1e2;
    }
    return '';
  }

  calcHoursAndMinutesReverse(num: any) {
    num = num * 60;

    var hours = Math.floor(num / 60);
    var minutes = Math.floor(num % 60);

    return hours * 60 + minutes;
  }

  curateDates(col: any, type: any) {
    each(col, c => {
      if (type === 'payment') { c.date = c.date ? dayjs(c.date.split('T')[0], 'YYYY-MM-DD') : null; return; }

      let startWithTime = c.start ? dayjs(c.start, 'YYYY-MM-DDThh:mm:ss') : null;
      let endWithTime = c.end ? dayjs(c.end, 'YYYY-MM-DDThh:mm:ss') : null;
      let officeLeaveWithTime = c.officeLeaveTime ? dayjs(c.officeLeaveTime, 'YYYY-MM-DDThh:mm:ss') : null;
      let officeReturnWithTime = c.officeReturnTime ? dayjs(c.officeReturnTime, 'YYYY-MM-DDThh:mm:ss') : null;

      const start = c.start ? dayjs(c.start.split('T')[0], 'YYYY-MM-DD') : null;
      const end = c.end ? dayjs(c.end.split('T')[0], 'YYYY-MM-DD') : null;
      c.startDate = start ? start.format('ddd DD/MM/YYYY') : null;
      c.endDate = end ? end.format('ddd DD/MM/YYYY') : null;
      c.sameDate = c.startDate === c.endDate;
      c.startTimeDisplay = startWithTime ? c.sameDate ? startWithTime.format('HH:mm') : startWithTime.format('ddd HH:mm') : null;
      c.endTimeDisplay = endWithTime ? c.sameDate ? endWithTime.format('HH:mm') : endWithTime.format('ddd HH:mm') : null;
      c.startTime = startWithTime ? startWithTime.format('HH:mm') : null;
      c.endTime = endWithTime ? endWithTime.format('HH:mm') : null;

      c.officeLeaveTimeDisplay = officeLeaveWithTime ? c.sameDate ? officeLeaveWithTime.format('HH:mm') : officeLeaveWithTime.format('ddd HH:mm') : null;
      c.officeReturnTimeDisplay = officeReturnWithTime ? c.sameDate ? officeReturnWithTime.format('HH:mm') : officeReturnWithTime.format('ddd HH:mm') : null;
      c.officeLeaveTime = officeLeaveWithTime ? officeLeaveWithTime.format('HH:mm') : null;
      c.officeReturnTime = officeReturnWithTime ? officeReturnWithTime.format('HH:mm') : null;

      c.endHour = c.endTime ? parseInt(c.endTime.split(':')[0], 10) : null;
      c.hours = type === 'schedules' ? (startWithTime && endWithTime) ? (Math.abs(endWithTime.diff(startWithTime, 'minutes')) / 60).toFixed(2) : null : c.hours;
      c.employeeIds = map(c.employees, e => e.id);
      //console.log('c', c);

      if (type === 'schedules') {

        const travelTime = c.jobType?.name && c.jobType?.name.includes('Therapy') ? 0.5 : 1;
        if (c.isTravelTimeChargeable && c.hours) {
          c.hours = Number(c.hours) + travelTime
          //startWithTime = startWithTime ? startWithTime.add(-15, 'minutes') : null;
          //endWithTime = endWithTime ? endWithTime.add(15, 'minutes'): null;          
        }

        c.employeeIds = map(c.employees, e => e.id);
        c.timeBadge = c.endHour ? this.getWorkDayPeriod(startWithTime, endWithTime) : 'N/A';
      }
      if (type === 'timeEntry') { c.employeeId = c.employee?.id; }
    })
  }

  // hasModules(org: any) {
  //   let hasModules: any = [];

  //   forEach(org.modules, module => {
  //       hasModules[module.code] = true;
  //   });

  //   return hasModules;
  // }

  makeCSVDataForNDISUpload(items: any, providerNDISRegNumber: any, ABN = '') {
    const ws_delivered_services = [['RegistrationNumber', 'NDISNumber', 'SupportsDeliveredFrom', 'SupportsDeliveredTo', 'SupportNumber', 'ClaimReference', 'Quantity', 'Hours', 'UnitPrice', 'GSTCode', 'AuthorisedBy', 'ParticipantApproved', 'InKindFundingProgram', 'ClaimType', 'CancellationReason', 'ABN of Support Provider']];
    items.forEach(ds => {
      //console.log('ds', ds);
      ws_delivered_services.push([
        // RegistrationNumber	NDISNumber	SupportsDeliveredFrom	SupportsDeliveredTo	SupportNumber	ClaimReference	Quantity	Hours	UnitPrice	GSTCode	AuthorisedBy	ParticipantApproved	InKindFundingProgram	ClaimType	CancellationReason, ABN of Support Provider									
        // 4050025688	430741128	2020-09-29	2020-09-29	01_019_0120_1_1	24905		2	49.3	P2		TRUE														
        providerNDISRegNumber,
        ds.ndisNumber,
        dayjs(ds.deliveredFrom).format('YYYY-MM-DD'),
        dayjs(ds.deliveredTo).format('YYYY-MM-DD'),
        ds.supportItem,
        ds.ref,
        ds.quantity,               // xero thinks everything is quantity so we dont have hours data
        ds.hoursAmount,            // hours have to be in 3:15 for 3.25 or 3:30 for 3.5 format     
        ds.unitAmount,
        "P2",
        "",    // APPROVED BY
        "TRUE", // CLIENT APPROVED
        "",   // InKindFundingProgram'
        ds.claimType,   // ClaimType
        ds.cancellationReason,   // CancellationReason   
        this.removeUnwantedSpaceDashDotChars(ds.abn)          
        //ds.abn
      ]);

      // ASSUME NO Cancelations for now
    });

    return ws_delivered_services;
  }

  removeUnwantedSpaceDashDotChars(input: any): string {
    //console.log('input', input + '*****')
    // Convert input to a string
    if (input != null) {
      input = input.toString();

      // Remove spaces, dashes, and dots from the input
      return input.replace(/[ .-]/g, '');
    }

    return input;
  }

  makeCSVDataForXeroInvoice(items) {
    // *ContactName	EmailAddress	POAddressLine1	POAddressLine2	POAddressLine3	POAddressLine4	POCity	PORegion	POPostalCode	POCountry	*InvoiceNumber	Reference	*InvoiceDate	*DueDate	InventoryItemCode	*Description	*Quantity	*UnitAmount	Discount	*AccountCode	*TaxType	TrackingName1	TrackingOption1	TrackingName2	TrackingOption2	Currency	BrandingTheme
    const ws_delivered_services = [['ContactName', 'EmailAddress', 'POAddressLine1', 'POAddressLine2', 'POAddressLine3', 'POAddressLine4', 'POCity', 'PORegion', 'POPostalCode', 'POCountry', 'InvoiceNumber', 'Reference', 'InvoiceDate', 'DueDate', 'InventoryItemCode', 'Description', 'Quantity', 'UnitAmount', 'Discount', 'AccountCode', 'TaxType', 'TrackingName1', 'TrackingOption1', 'TrackingName2', 'TrackingOption2', 'Currency', 'BrandingTheme']];
    const invoiceId = 'CS-1009';
    const contact = 'NDIS';
    const accountCode = "203";
    const taxType = 'GST Free Income';

    items.forEach((ds: any) => {
      //console.log('ds', ds);
      const dateString = dayjs(ds.deliveredFrom).format('DD/MM/YYYY') !== dayjs(ds.deliveredTo).format('DD/MM/YYYY')
        ? dayjs(ds.deliveredFrom).format('DD/MM/YYYY') + '~' + dayjs(ds.deliveredTo).format('DD/MM/YYYY')
        : dayjs(ds.deliveredFrom).format('DD/MM/YYYY');

      ws_delivered_services.push([
        contact,
        '', '', '', '', '', '', '', '', '',   // contact address fields
        invoiceId,  // invoice number
        , // // reference for this new invoice
        dayjs().format('DD/MM/YYYY'), // invoice date
        dayjs().add(7).format('DD/MM/YYYY'), // due date
        ds.supportItem, //InventoryItemCode
        'Ref:' + ds.ref + ' ' + ds.clientName + ' ' + ds.supportItemName + ' ' + dateString, //*Description
        ds.quantity, //*Quantity	// ds.hoursAmount  // xero thinks everything is quantity so we dont have hours data
        ds.unitAmount, //*UnitAmount	
        '', //Discount	
        accountCode, //*AccountCode	
        taxType, //*TaxType	
        '', '', '', '', '', //TrackingName1	 //TrackingOption1 //TrackingName2	//TrackingOption2	               
        '', //Currency	
        '', //BrandingTheme
      ]);

      // ASSUME NO Cancelations for now
    });

    return ws_delivered_services;
  }

  cloneJobData(job: any) {
    const schedules = job.schedules;
    let clonedCharges = clone(job.charges);
    map(clonedCharges, c => {
      delete c.id;
      delete c.payRequest;
      delete c.expectedRateItem;
      delete c.taxTypeItem;
    });

    let clonedSchedules = clone(schedules);
    map(clonedSchedules, s => { delete s.id; });

    let clonedJob = cloneDeep(job);
    delete clonedJob.id;
    clonedJob.jobNumber = 0;  // so that we get a new unique one
    clonedJob.clientId = clonedJob.client.id;

    delete clonedJob.summary;
    delete clonedJob.client; //clientId should suffice
    delete clonedJob.currentPMAgreement
    delete clonedJob.invoiceDate;
    delete clonedJob.invoiceStatus;
    delete clonedJob.invoiceSentDate;
    delete clonedJob.invoicePaidOn;
    delete clonedJob.xeroId;
    // delete clonedJob.employees /// delete or no?

    clonedJob.charges = []; //have the cloned above
    clonedJob.schedules = [];
    clonedJob.timeEntries = [];
    clonedJob.timesheetLineItems = [];
    clonedJob.uniqEmployees = [];

    return { job: clonedJob, charges: clonedCharges, schedules: clonedSchedules };
  }

  cloneJobDataForRecurringJob(job: any) {
    let clonedCharges = clone(job.charges);
    map(clonedCharges, c => {
      delete c.id;
      delete c.payRequest;
      delete c.expectedRateItem;
      delete c.taxTypeItem;
    });

    let clonedSchedules = clone(job.schedules);
    map(clonedSchedules, s => {
      delete s.id;
    });

    let clonedJob = cloneDeep(job);
    delete clonedJob.id;
    delete clonedJob.jobNumber;
    clonedJob.jobClass = "T";

    delete clonedJob.summary;
    delete clonedJob.client;
    delete clonedJob.currentPMAgreement

    clonedJob.charges = []; //have the cloned above
    clonedJob.schedules = [];

    delete clonedJob.timeEntries;  // remove lster
    delete clonedJob.timesheetLineItems;  //remove later

    delete clonedJob.uniqEmployees;

    // console.log('clonedJob', clonedJob);
    // console.log(clonedCharges);
    // console.log(clonedSchedules);

    return { job: clonedJob, charges: clonedCharges, schedules: clonedSchedules };
  }

  jobChargeKmsAssistCreateItem(inEffectPriceGuide: any, job: any) {
    //let firstChargeItem: any = first(filter(job.charges, c => c.rate > 10));  // to make sure it is a support item, not travel itself

    let firstChargeItem: any = first(job.charges);

    if (firstChargeItem) {
      let itemCodeType = firstChargeItem.itemCodeType;   // e.g NDIS
      let taxType = firstChargeItem.taxType; // e.g FREE
      let chartAccountCode = '224';
      let unit = 1;

      let supportItemObject = this.supportItemObject(firstChargeItem.itemCode);
      //console.log('supportItemObject', supportItemObject);

      let itemCode = this.getActivityBasedTransportItemCode(supportItemObject, inEffectPriceGuide)

      const newItem: any = {
        jobId: job.id,
        quantity: job.kmsAppovedTimeEntry,
        unit: unit,
        taxType: taxType,
        chartAccountCode: chartAccountCode,
        supportItemNumber: itemCode,
        itemCodeType: itemCodeType,
      };

      return newItem;
    }

    return null;
  }

  jobChargeAssistCapitalCostCentreCreateItem(inEffectPriceGuide: any, job: any, supportItem: any) {
    console.log('supportItem', supportItem);

    if (supportItem) {
      let itemCodeType = supportItem.itemCodeType;   // e.g NDIS
      let taxType = supportItem.taxType; // e.g FREE
      let chartAccountCode = '208';

      let firstItemObject = this.supportItemObject(supportItem.supportItemNumber);
      console.log('firstItemObject', firstItemObject);

      const lookupItems = ['Centre Capital Cost'];
      const supportItems = filter(inEffectPriceGuide.records, (r: any) => this.contains(r.searchField, lookupItems))
      console.log('supportItems', supportItems);
      
      map(supportItems, si => si.object = this.supportItemObject(si.supportItemNumber));

      console.log('supportItems', supportItems);

      const item = find(supportItems, si => si.object.registrationGroup == firstItemObject?.registrationGroup)

      console.log('item', item);

      const newItem: any = {
        jobId: job.id,
        unit: item?.baseRate,
        taxType: taxType,
        chartAccountCode: chartAccountCode,
        supportItemNumber: item?.supportItemNumber + ' ' + item?.supportItemName,
        itemCodeType: itemCodeType,
      };

      console.log('newItem', newItem)

      return newItem;
    }

    return null;
  }

  jobChargeAssistCommunityParticipationCreateItem(inEffectPriceGuide: any, job: any, supportItem: any, rate: any) {
    console.log('supportItem', supportItem);

    if (supportItem) {
      let itemCodeType = supportItem.itemCodeType;   // e.g NDIS
      let taxType = supportItem.taxType; // e.g FREE
      let chartAccountCode = '208';

      let firstItemObject = this.supportItemObject(supportItem.supportItemNumber);
      console.log('firstItemObject', firstItemObject);

      const lookupItems = ['Community Participation Activities'];
      const supportItems = filter(inEffectPriceGuide.records, (r: any) => this.contains(r.searchField, lookupItems))
      console.log('supportItems', supportItems);
      
      map(supportItems, si => si.object = this.supportItemObject(si.supportItemNumber));

      const item = supportItems[0];
      console.log('item', item);

      const newItem: any = {
        jobId: job.id,
        unit: rate,
        taxType: taxType,
        chartAccountCode: chartAccountCode,
        supportItemNumber: item?.supportItemNumber + ' ' + item?.supportItemName,
        itemCodeType: itemCodeType,
      };

      console.log('newItem', newItem)

      return newItem;
    }

    return null;
  }

  contains(str: any, arr: any) {
    var isEvery = arr.every(item => str.includes(item));
    return isEvery

  }

  pmInvoiceKmsAssistCreateItem(inEffectPriceGuide: any, invoice: any) {
    let firstChargeItem: any = first(filter(invoice.lineItems, li => li.rate > 10));  // to make sure it is a support item, not travel itself

    // console.log('invoice', invoice)
    // console.log('firstChargeItem', firstChargeItem)

    if (firstChargeItem) {
      let supportItemObject = this.supportItemObject(firstChargeItem.supportItemNumber);
      //console.log('supportItemObject', supportItemObject)

      let itemCode = this.getActivityBasedTransportItemCode(supportItemObject, firstChargeItem.inEffectPriceGuide)
      //console.log('itemCode', itemCode)

      return itemCode
    }
  }

  getActivityBasedTransportItemCode(supportItemObject: any, inEffectPriceGuide: any) {
    let sequenceNumber;
    let itemCode;

    let supportItemNumbers = inEffectPriceGuide ? inEffectPriceGuide.records : [];

    if (supportItemObject?.supportCategory === '04') {
      switch (supportItemObject.registrationGroup) {
        case '0125':
          sequenceNumber = '590'
          break
        case '0136':
          sequenceNumber = '591'
          break
        case '0104':
          sequenceNumber = '592'
          break
        case '0133':
          sequenceNumber = '821'
          break
      }

      itemCode = '04_' + sequenceNumber + '_' + supportItemObject?.registrationGroup + '_6_1 Activity Based Transport';
    }
    // for provider travel - non labour costs we can use the following for all cats, but for now just use for 15
    // because 04 activity based transport may be required for 04,07,08,0910,11,13 groups

    else if (supportItemObject?.supportCategory === '15' || supportItemObject?.supportCategory === '01') {
      const sequenceNumber = '799';
      let lookupItem =
        supportItemObject.supportCategory + '_' +
        sequenceNumber + '_' +
        supportItemObject.registrationGroup + '_' +
        supportItemObject.outcomeDomain + '_' +
        supportItemObject.supportPurpose;

      let itemCodeObject = find(supportItemNumbers, i => i.supportItemNumber === lookupItem);
      itemCode = itemCodeObject.searchField;
    }
    else { itemCode = "Mileage per kms" }

    return itemCode;
  }

  jobChargeAssistCreateItem(inEffectPriceGuide: any, job: any, item: any) {
    const jobId = job.id
    // TODO age should be calculated at time of getting plan because the age requirmenent below for under 7 is based on when the plan went into effect not todays date

    const ageAtStartOfPlan = dayjs().diff(dayjs(job.client?.dob), 'y');
    //console.log('ageAtStartOfPlan', ageAtStartOfPlan)

    // calc how many minutes in job
    //var mins = sumBy(job.schedules, s => s.

    const newItem: any = {
      jobId: jobId,
      quantity: item.hours,
    };

    // get from start time and public holiday db
    const publicHoliday = false;
    const startDate = dayjs(item.start);
    const dayOfWeek = dayjs.utc(item.start).day();  // 0 sunday

    let lookupItem = '';

    //console.log('item', item);

    let supportItemNumbers = inEffectPriceGuide ? inEffectPriceGuide.records : [];
    //console.log('supportItemNumbers', supportItemNumbers);

    const N2F2Items = ['RES', 'FOOD', 'REP', 'PC', 'PH', 'EM']
    newItem.claimType = N2F2Items.includes(item.workType) ? 'NF2F' : null;

    // TODO temp
    if (item.jobType?.name === 'NDIS Cleaning') {
      newItem.chartAccountCode = "202";
      lookupItem = '01_020_0120_1_1';
    }

    if (item.jobType?.name === 'NDIS Gardening') {
      newItem.chartAccountCode = "207";
      lookupItem = '01_019_0120_1_1';
    }

    if (item.jobType?.name === 'NDIS Therapy Support Other') {
      item.paySource = 'NDIS' // temp
      newItem.chartAccountCode = '207';
      lookupItem = ageAtStartOfPlan < 8 ? '15_005_0118_1_3' : '15_056_0128_1_3'
    }

    if (item.jobType?.name === 'NDIS Therapy Assistant') {
      item.paySource = 'NDIS' // temp
      newItem.chartAccountCode = '207';
      lookupItem = '15_053_0128_1_3'
    }

    if (item.jobType?.name === 'NDIS Speech Therapy') {
      item.paySource = 'NDIS'  //temp
      newItem.chartAccountCode = '207';
      lookupItem = ageAtStartOfPlan < 8 ? '15_005_0118_1_3' : '15_622_0128_1_3'
    }

    if (item.jobType?.name === 'NDIS Occupational Therapy') {
      item.paySource = 'NDIS' //temp
      newItem.chartAccountCode = '207';
      lookupItem = ageAtStartOfPlan < 8 ? '15_005_0118_1_3' : '15_617_0128_1_3'
    }

    // need to consider if high intensity and also if using T codes
    if (item.jobType?.name === 'NDIS Access Community' || item.jobType?.name === 'NDIS Community') {
      newItem.chartAccountCode = "201";
      if (publicHoliday) {
        lookupItem = '04_102_0125_6_1';
      } else if (dayOfWeek > 0 && dayOfWeek < 6) {
        if (item.timeBadge === 'EVENING' || item.timeBadge === 'NIGHT') {
          lookupItem = '04_103_0125_6_1';
        } else {
          lookupItem = '04_104_0125_6_1';
        }
      } else if (dayOfWeek === 6) {
        lookupItem = '04_105_0125_6_1';
      } else if (dayOfWeek === 0) {
        lookupItem = '04_106_0125_6_1';
      }

      // TODO need to check if evening, night or day
    }

    if (item.jobType?.name === 'NDIS Group Community') {
      newItem.chartAccountCode = "209";

      // new pricing allows 3 different line items, Center Capital Cost, Prep Time, Supports Delivered and also optioanl time travelled for worker to get there as well
      //job.groupActivityClients?. which one

      // TODO need to check if evening, night or day
    }

    if (item.jobType?.name === 'NDIS Group Centre') {
      newItem.chartAccountCode = "208";

      // new pricing allows 3 different line items, Center Capital Cost, Prep Time, Supports Delivered and also optioanl time travelled for worker to get there as well
      
      // do we need to return an array of items for this one?
      // or do we let user click to return the Center Capital Cost etc

      // TODO need to check if evening, night or day
    }

    if (item.jobType?.name === 'NDIS STA') {
      newItem.chartAccountCode = "204";

      if (publicHoliday) {
        lookupItem = '01_061_0115_1_1';
      } else if (dayOfWeek > 0 && dayOfWeek < 6) {        
        lookupItem = '01_058_0115_1_1';        
      } else if (dayOfWeek === 6) {
        lookupItem = '01_059_0115_1_1';
      } else if (dayOfWeek === 0) {
        lookupItem = '01_060_0115_1_1';
      }

      newItem.quantity = 1
    }

    if (item.jobType?.name === 'NDIS School Leaver Employment Supports') {
      newItem.chartAccountCode = "213";
      lookupItem = '10_021_0102_5_3';
    }

    if (item.jobType?.name === 'NDIS Employment Assistance (non school leavers)') {
      newItem.chartAccountCode = "213";
      lookupItem = '10_016_0102_5_3';
    }

    if (item.jobType?.name === 'NDIS Assist-Personal Activities') {
      newItem.chartAccountCode = "212";

      if (publicHoliday) {
        lookupItem = '01_012_0107_1_1';
      } else if (dayOfWeek > 0 && dayOfWeek < 6) {
        if (item.timeBadge === 'EVENING') {
          lookupItem = '01_015_0107_1_1';
        } else if (item.timeBadge === 'NIGHT') {
          lookupItem = '01_002_0107_1_1';
        } else {
          lookupItem = '01_011_0107_1_1';
        }
      } else if (dayOfWeek === 6) {
        lookupItem = '01_013_0107_1_1';
      } else if (dayOfWeek === 0) {
        lookupItem = '01_014_0107_1_1';
      }

      // get all schedules if same activity and same day group
      const scheduleSameType = filter(job.schedules, s =>
        s.jobType?.name == item.jobType?.name &&
        s.timeBadge == item.timeBadge
      )

      // console.log('item.timeBadge', item.timeBadge)
      // console.log('scheduleSameType', scheduleSameType)

      const totalSameTypeHours = sumBy(scheduleSameType, s => Number(s.hours))
      // temporarily give all the hours to current item
      newItem.quantity = totalSameTypeHours
    }

    // standard rates for SIL
    if (item.jobType?.name === 'NDIS Assistance in SIL') {
      newItem.chartAccountCode = "214";

      if (publicHoliday) {
        lookupItem = '01_806_0115_1_1';
      } else if (dayOfWeek > 0 && dayOfWeek < 6) {
        if (item.timeBadge === 'EVENING') {
          lookupItem = '01_802_0115_1_1';
        } else if (item.timeBadge === 'NIGHT') {
          lookupItem = '01_803_0115_1_1';
        } else {
          lookupItem = '01_801_0115_1_1';
        }
      } else if (dayOfWeek === 6) {
        lookupItem = '01_804_0115_1_1';
      } else if (dayOfWeek === 0) {
        lookupItem = '01_805_0115_1_1';
      }

      // get all schedules if same activity and same day group
      const scheduleSameType = filter(job.schedules, s =>
        s.jobType?.name == item.jobType?.name &&
        s.timeBadge == item.timeBadge
      )

      // console.log('item.timeBadge', item.timeBadge)
      // console.log('scheduleSameType', scheduleSameType)

      const totalSameTypeHours = sumBy(scheduleSameType, s => Number(s.hours))
      // temporarily give all the hours to current item
      newItem.quantity = totalSameTypeHours
    }

    // ** TEMP SPECIAL ITEM ** //
    if (item.jobType?.name === 'NDIS Behaviour Early Intervention') {
      item.paySource = 'NDIS' //temp
      newItem.chartAccountCode = '207';
      lookupItem = '15_621_0128_1_3'
    }

    // override codes and quantity if sleepover
    if (item.isSleepover) {
      newItem.quantity = 1
      if (item.jobType?.name == 'NDIS Assistance in SIL') {
        lookupItem = '01_832_0115_1_1'
      } else {
        lookupItem = '01_010_0107_1_1';   // basic 
      }
    }

    // console.log('dayOfWeek', dayOfWeek);
    // console.log('timeBadge', item.timeBadge);
    // console.log('lookupItem', lookupItem);
    // console.log('paySource', item.paySource);
    // console.log('jobType', item.jobType);

    if (item.jobType?.name && (item.paySource === 'NDIS' || (startsWith(item.jobType?.name, 'NDIS')))) {
      newItem.taxType = 'FREE';
      newItem.itemCodeType = 'NDIS'

      //console.log('try to find item');

      const findItem = find(supportItemNumbers, i => i.supportItemNumber === lookupItem);
      newItem.supportItemNumber = findItem ? findItem.searchField : '';
      newItem.unit = findItem ? findItem.baseRate : 0;

      //console.log('findItem', findItem);
    }

    if (item.jobType?.name === 'Medicare Speech Therapy') {
      newItem.chartAccountCode = "207";
      newItem.taxType = 'FREE';
      newItem.itemCodeType = 'Medicare'
      newItem.supportItemNumber = 'Speech Therapy: Daytime - 45 mins';
      newItem.unit = 130;
      newItem.quantity = 1;  // should check the amount of time planned and work out how many 45 mins fit in to get quanity
    }

    if (item.jobType?.name === 'Medicare Occupational Therapy') {
      newItem.chartAccountCode = "207";
      newItem.taxType = 'FREE';
      newItem.itemCodeType = 'Medicare'
      newItem.supportItemNumber = 'Occupational Therapy Services: Daytime - 45 mins';
      newItem.unit = 130;
      newItem.quantity = 1;   // should check the amount of time planned and work out how many 45 mins fit in to get quanity
    }

    if (item.jobType?.name === 'Private Speech Therapy') {
      newItem.chartAccountCode = "207";
      newItem.taxType = 'GST10';
      newItem.itemCodeType = 'Private'
      newItem.supportItemNumber = 'Speech Therapy: Daytime - 45 mins';
      newItem.unit = 130;
      newItem.quantity = 1;  // should check the amount of time planned and work out how many 45 mins fit in to get quanity
    }

    if (item.jobType?.name === 'Private Occupational Therapy') {
      newItem.chartAccountCode = "207";
      newItem.taxType = 'GST10';
      newItem.itemCodeType = 'Private'
      newItem.supportItemNumber = 'Occupational Therapy Services: Daytime - 45 mins';
      newItem.unit = 130;
      newItem.quantity = 1;   // should check the amount of time planned and work out how many 45 mins fit in to get quanity
    }


    

    if (item.jobType?.name === 'Cleaning - Domestic') {
      newItem.chartAccountCode = "254";
      newItem.taxType = 'GST10';
      newItem.itemCodeType = 'Cleaning'
      switch (item.timeBadge) {
        case 'DAYTIME':
          newItem.supportItemNumber = 'Cleaning: Domestic - Weekday';
          newItem.unit = 65;
          break;
        case 'SATURDAY':
          newItem.supportItemNumber = 'Cleaning: Domestic - Saturday';
          newItem.unit = 85;
          break;
        case 'SUNDAY':
          newItem.supportItemNumber = 'Cleaning: Domestic - Saturday';
          newItem.unit = 1100;
          break;
        case 'PUBLIC HOLIDAY':
          newItem.supportItemNumber = 'Cleaning: Domestic - Public Holiday';
          newItem.unit = 130;
          break;
        default:
          break;
      }
    }

    if (item.jobType?.name === 'Cleaning - Commercial') {
      newItem.chartAccountCode = "251";
      newItem.taxType = 'GST10';
      newItem.itemCodeType = 'Cleaning'
      switch (item.timeBadge) {
        case 'DAYTIME':
          newItem.supportItemNumber = 'Cleaning: Commercial - Weekday';
          newItem.unit = 65;
          break;
        case 'EVENING':
          newItem.supportItemNumber = 'Cleaning: Commercial - Evening';
          newItem.unit = 85;
          break;
        case 'SATURDAY':
          newItem.supportItemNumber = 'Cleaning: Commercial - Saturday';
          newItem.unit = 85;
          break;
        case 'SUNDAY':
          newItem.supportItemNumber = 'Cleaning: Commercial - Saturday';
          newItem.unit = 100;
          break;
        case 'PUBLIC HOLIDAY':
          newItem.supportItemNumber = 'Cleaning: Commercial - Public Holiday';
          newItem.unit = 150;
          break;
        default:
          break;
      }
    }

    if (item.jobType?.name === 'Concierge') {
      newItem.chartAccountCode = "223";
      newItem.taxType = 'GST10';
      newItem.itemCodeType = 'Homecare'
      switch (item.timeBadge) {
        case 'DAYTIME':
          newItem.supportItemNumber = 'Concierge: Daytime - Weekday';
          newItem.unit = 65;
          break;
        case 'SATURDAY':
          newItem.supportItemNumber = 'Concierge: Daytime - Saturday';
          newItem.unit = 85;
          break;
        case 'SUNDAY':
          newItem.supportItemNumber = 'Concierge: Daytime - Saturday';
          newItem.unit = 100;
          break;
        case 'PUBLIC HOLIDAY':
          newItem.supportItemNumber = 'Concierge: Daytime - Public Holiday';
          newItem.unit = 110;
          break;
        default:
          break;
      }
    }

    return newItem;
  }

  getCatDetails(i, contract) {
    let id = i < 10 ? "0" + i : i;
    let name = "cat" + id.toString();
    let detailName = "cat" + id.toString() + 'Detail';
    let detail = JSON.parse(contract[detailName]);

    return detail;
  }

  getCatAmount(i, contract) {
    let id = i < 10 ? "0" + i : i;
    let name = "cat" + id.toString();    
    let amount = contract[name];

    return amount;
  }

  getTodoCategories() {
    return ['Client', 'Job', 'Office', 'HR', 'Course', 'Other'];
  }

  getTodoStatuses() {
    return ['Idea', 'Not Started', 'In Progress', 'Done', 'Cancelled'];
  }

  getCategories() {
    const cats = [
      { id: '01', name: 'Assistance with daily life (includes SIL)', on: true },
      { id: '02', name: 'Transport', on: true },
      { id: '03', name: 'Consumables', on: true },
      { id: '04', name: 'Assistance with social and community participation', on: true },
      { id: '05', name: 'Assistive technology', on: true },
      { id: '06', name: 'Home modifications', on: true },
      { id: '07', name: 'Coordination of Supports', on: true },
      { id: '08', name: 'Improved living arrangements', on: true },
      { id: '09', name: 'Increased social and community participation', on: true },
      { id: '10', name: 'Finding and keeping a job', on: true },
      { id: '11', name: 'Improved relationships', on: true },
      { id: '12', name: 'Improved health and wellbeing', on: true },
      { id: '13', name: 'Improved learning', on: true },
      { id: '14', name: 'Improved life choices', on: true },
      { id: '15', name: 'Improved daily living skills', on: true },
    ];
    map(cats, (c: any) => c.label = `${c.id} - ${c.name}`);
    return cats;
  }

  supportItemCode(supportItemCode: any) {
    //regex didn't work so basic split
    let r = supportItemCode.split(' ');
    let code = r[0];

    //console.log('code', code);

    return code;
  }

  supportItemObject(supportItemCode: any) {
    // first remove any text that may be here
    if (supportItemCode != null) {
      let r = supportItemCode.split(' ');
      let code = r[0];

      //console.log('code', code);

      if (code) {
        const arr = split(code, '_');
        //check we have 5
        // console.log('arr', arr);
        // console.log('arr.length', arr.length);
        if (arr.length === 5) {
          const supportItemObject: NDISSupportItemObject = {
            supportCategory: arr[0],
            sequenceNumber: arr[1],
            registrationGroup: arr[2],
            outcomeDomain: arr[3],
            supportPurpose: arr[4],
          }
          return supportItemObject;
        }
      }
    }

    return null;
  }

  getLatestPlanManager(pmAgreements: any, managedType: string = 'SUPPORTS') {
    const allPmAgreements = filter(pmAgreements, a => a.managedType === managedType);
    const pmAgreement = last(orderBy(allPmAgreements, 'startDate'));
    return pmAgreement;
  }

  getCategories3() {
    return [
      {
        id: '01',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Assistance with Daily Life',
        desc: 'Household decision making, personal care and domestic tasks Assistance with household tasks, Meals on Wheels preparation and delivery of meals, assistance with and/or supervising tasks of daily life in independent living or shared living environment, Short term Accommodation and Assistance (e.g. Respite care).'
      },
      {
        id: '02',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Transport',
        desc: 'Transport, specialised transport to school education program, employment , community. Travel enables participants to access the community for educational, recreational and vocational purposes. Participants receive funds fortnightly in advance to pay for services of their choice.'
      },
      {
        id: '03',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Consumables',
        desc: 'Consumables are a support category available to assist participants with purchasing everyday items. Supports such as interpreting, translating, continence and home enteral nutrition (HEN) products are included in this category.'
      },
      {
        id: '04',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Assistance Social Participation',
        desc: 'Tuition fees, art classes, sports coaching and similar activities that build skills and independence. Camps, classes and vacation activities that have capacity building, mentoring or peer support and individual skill development.'
      },
      {
        id: '05',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Assistive Technology',
        desc: 'Assistive equipment for recreation, assistive products for household tasks, assistive products for personal care and safety. Vehicle modifications including the installation or changes. Equipment in a vehicle to enable a participant to travel safely as a passenger or to drive.'
      },
      {
        id: '06',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Home Modifications',
        desc: 'Stair climber, certification or approval of home modifications, elevator-home, grab rails, modification to bathroom, toilet, laundry, kitchen, structural work, modification project manager or building certifier.'
      },
      {
        id: '07',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Coordination of Supports',
        desc: 'Support connection, coordination of supports, specialist coordination. Assistance to strengthen participant’s ability to connect with informal, mainstream and funded supports, and to increase capacity to maintain support relationships. Resolve service delivery issues and points of crisis.'
      },
      {
        id: '08',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Improved Living Arrangements',
        desc: 'Group homes, large residential settings, drop in support, individual accommodation support package, outreach program, disability housing and support initiative (DHASI). Assistance with accommodation and tenancy obligations, individual skill development and training.'
      },
      {
        id: '09',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Increased Social Participation',
        desc: 'Recreation, peer support, community participation, life choices, active ageing, community access programs, vacation care, Out of School Hours Care (OOSH), weekend programs, flexible respite, centre based respite, group fitness for people with disability.'
      },
      {
        id: '10',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Find Keep Job',
        desc: 'Transition to employment, transition to work. Work skills, workability, individual employments support, employment preparation, assistance in employment (ADE).'
      },
      {
        id: '11',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Improved Relationships',
        desc: 'Intensive behaviour intervention, development and monitoring of management plan. Positive behaviour management strategies, individualised social skills development.'
      },
      {
        id: '12',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Improved Health and Wellbeing',
        desc: 'Exercise physiology, personal training, dietician consultation and plan development.'
      },
      {
        id: '13',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Improved Learning',
        desc: 'Transition through school and to further education.'
      },
      {
        id: '14',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Improved Life Choices',
        desc: 'Financial intermediary- setup costs, training in planning and plan management building financial skills, organisational skills, and enhancing the participant’s ability to direct their supports and/or develop self-management capabilities.'
      },
      {
        id: '15',
        subCats: [],
        amountPaid: 0,
        amountRejected: 0,
        name: 'Improved Daily Living',
        desc: 'Assessment, training, development and/or therapy to assist in the development of, or increase in skills for independence and community participation and therapeutic supports.'
      }
    ];
  }

  getSubcategories() {
    return {
      Core: ['01', '02', '03', '04'],
      Capital: ['05', '06'],
      CB: ['07', '08', '09', '10', '11', '12', '13', '14', '15']
    };
  }

  getTaxTypes() {
    return [
      { code: "FREE", name: "GST FREE" },
      { code: "GST10", name: "GST 10%" }
    ];
  }

  getClaimTypes() {
    return [
      { id: 'TRAN', name: 'Provider Travel' },
      { id: 'CANC', name: 'Cancellation' },
      { id: 'NF2F', name: 'Non-Face-to-Face Services' },
      { id: 'REPW', name: 'NDIA Required Report' },
      { id: 'CCC', name: 'Center Capital Cost' }
    ];

    // claimType  “(Blank)” or CANC or REPW or TRAN or NF2F
    // “(Blank)” – Direct Service. You must leave field blank. 
  }

  getCancellationReasons() {
    return [
      { id: 'NOBILL', name: 'Early Cancel' },
      { id: 'NSDH', name: 'Health Reasons' },
      { id: 'NSDF', name: 'Facility Reasons' },
      { id: 'NSDT', name: 'Transport Reasons' },
      { id: 'NSDO', name: 'Other Reasons' },
    ]
    // Reason of the cancellation type
    // NSDH: No show due to health reason.
    // NSDF: No show due to family issues.
    // NSDT: No show due to unavailability of transport.
    // NSDO: Other
  }

  getJobStatuses() {
    return [
      { id: 1, name: 'scheduled' },
      { id: 2, name: 'in_progress' },
      { id: 3, name: 'completed' },
      { id: 4, name: 'canceled' }
    ];
  }

  getPaySources() {
    return [
      { id: 'NDIS', name: 'NDIS' },
      // { id: 'NDIS-SUB', name: 'NDIS Subcontractor' },       
      // { id: 'SELF', name: 'Self' },
      { id: 'SUB', name: 'Subcontractor' },
      { id: 'INS', name: 'Insurance' },
      { id: 'OTHER', name: 'Other' }
    ];
  }

  getWorkTypes() {
    return [
      // { code: "DS", name: "Direct Support" },
      // { code: "IS", name: "Indirect Support" },
      { code: "SUPP", name: "Supports" },
      { code: "EM", name: "Email" },
      { code: "I", name: "Intake" },
      { code: "EST", name: "Establishment Fee" },
      { code: "C", name: "Coordination" },
      { code: "TL", name: "Team Leader" },
      { code: "MG", name: "Meet & Greet" },
      { code: "PC", name: "Phone Conference" },
      { code: "PH", name: "Phone Call" },
      { code: "REP", name: "Report Writing" },
      { code: "RES", name: "Resources" },
      { code: "FOOD", name: "Food Preparation" },
      { code: "SS", name: "Shadow Shift" },
      { code: "TT", name: "Travel Time" },
      // { code: "PD", name: "Professional Development" },
      { code: "Q", name: "Quote" },

    ];
  }

  getItemCodeTypes() {
    return [
      { code: 'NDIS', title: 'NDIS' },
      { code: 'Xero', title: 'Cleaning' },
      { code: 'Homecare', title: 'Homecare' },
      { code: 'Medicare', title: 'Medicare' },
      { code: 'Gardening', title: 'Gardening' },
      { code: 'LicensedBuilding', title: 'Licensed Building' },
    ];
  }

  getChartAccountCodes(organisationId: any) {
    if (organisationId === '05140d53-c80d-47a4-8b37-08d8ae39386a')
      return this.getChartAccountCodesSOS();

    if (organisationId === 'ef84e332-57b2-4ff4-8b38-08d8ae39386a')
      return this.getChartAccountCodesICL();

    return null;
  };

  getChartAccountCodesSOS() {
    return [   // to be in db for organisation - either imported from xero or manually entered themeslves
      { name: "O65: Home Care", accountCode: 220 },
      { name: "O65: Concierge Service", accountCode: 223 },
      { name: "Clean: Commercial", accountCode: 251 },
      { name: "Clean: Bond", accountCode: 252 },
      { name: "Clean: Gardening", accountCode: 245 },
      { name: "Clean: Holiday", accountCode: 253 },
      { name: "Clean: Domestic", accountCode: 254 },
      { name: "Clean: Kms", accountCode: 255 },
      { name: "NDIS: Community Access", accountCode: 201 },      
      { name: "NDIS: In House Support", accountCode: 202 },
      { name: "NDIS: Capacity Building", accountCode: 203 },
      { name: "NDIS: Clinic & Support", accountCode: 204 },
      { name: "NDIS: SIL Revenue", accountCode: 205 },
      { name: "NDIS: SDA", accountCode: 206 },
      { name: "NDIS: Gardening", accountCode: 207 },
      { name: "NDIS: Childhood", accountCode: 210 },
      { name: "Transport: Private and Plan Managed", accountCode: 224 },
      { name: "Licensed Building", accountCode: 246 },
    ];
  }

  getChartAccountCodesICL() {
    return [   // to be in db for organisation     
      { name: "NDIS: Community Access", accountCode: 201 },
      { name: "NDIS: Short Term Accomodation", accountCode: 204 },
      { name: "NDIS: Direct Supports", accountCode: 205 },
      { name: "NDIS: Therapy Supports", accountCode: 207 },
      { name: "NDIS: Group Activities Center", accountCode: 208 },
      { name: "NDIS: Group Activties Community", accountCode: 209 },
      { name: "NDIS: Coordination of Supports", accountCode: 210 },
      { name: "NDIS: Behavioural Supports", accountCode: 211 },
      { name: "NDIS: Assist Personal Activities", accountCode: 212 },
      //{ name: "NDIS: Support For School Leavers", accountCode: 213 },
      { name: "NDIS: Employment Assistance", accountCode: 213 },
      { name: "NDIS: Transport", accountCode: 224 },
    ];
  }

  getRegistrationGroups() {
    return [
      // Registration Numbe, Name, Registration Process, NDIS Practice Standards Module
      { id: '0101', name: 'Accommodation / Tenancy Assistance', regProcess: 'verficiation', psm: 'verficiation' },
      { id: '0102', name: 'Assistance to Access and Maintain Employment or Higher Education', regProcess: 'certification', psm: 'core' },
      { id: '0103', name: 'Assistive product for personal care and safety', regProcess: 'verficiation', psm: 'verficiation' },
      { id: '0104', name: 'High Intensity Daily Personal Activities', regProcess: 'certification', psm: 'Core Module + High Intensity Daily Personal Activities Module' },
      { id: '0105', name: 'Personal Mobility Equipment' },
      { id: '0106', name: 'Assistance in Coordinating or Managing Life Stages, Transitions And Supports' },
      { id: '0107', name: 'Daily Personal Activities' },
      { id: '0108', name: 'Assistance with Travel/Transport Arrangements' },
      { id: '0109', name: 'Vehicle Modifications' },
      { id: '0110', name: 'Specialist Behaviour Support', regProcess: 'certificationAndModule' },
      { id: '0111', name: 'Home Modification Design and Construction' },
      { id: '0112', name: 'Assistive Equipment for Recreation' },
      { id: '0113', name: 'Vision Equipment' },
      { id: '0114', name: 'Community Nursing Care' },
      { id: '0115', name: 'Assistance with Daily Life Tasks in a Group or Shared Living Arrangement' },
      { id: '0116', name: 'Innovative Community Participation' },
      { id: '0117', name: 'Development of Daily Living and Life Skills' },
      { id: '0118', name: 'Early intervention early childhood', regProcess: 'certificationAndModule' },
      { id: '0119', name: 'Specialised Hearing Services' },
      { id: '0120', name: 'Household Tasks' },
      { id: '0121', name: 'Interpreting and Translation' },
      { id: '0122', name: 'Hearing Equipment' },
      { id: '0123', name: 'Assistive Products for Household Tasks' },
      { id: '0124', name: 'Communication & Information Equipment' },
      { id: '0125', name: 'Participation in Community, Social and Civic Activities' },
      { id: '0126', name: 'Exercise Physiology & Personal Well-being Activities' },
      { id: '0127', name: 'Management of Funding for Supports in Participants Plans' },
      { id: '0128', name: 'Therapeutic Supports' },
      { id: '0129', name: 'Specialised Driver Training' },
      { id: '0130', name: 'Assistance Animals' },
      { id: '0131', name: 'Specialised Disability Accommodation' },
      { id: '0132', name: 'Specialist Support Coordination', regProcess: 'certificationAndModule' },
      { id: '0133', name: 'Specialised Supported Employment' },
      { id: '0134', name: 'Hearing Services' },
      { id: '0135', name: 'Custom Prostheses and Orthoses' },
      { id: '0136', name: 'Group and Centre Based Activities' },
    ]
  }

  getRegistrationGroupName(key: any) {
    const groups = this.getRegistrationGroups();
    let item = find(groups, g => g.id == key);
    return item;
  }

  getInvoiceStatuses() {
    return [
      { value: 'APPR', text: 'Approved' },
      { value: 'EXPORT', text: 'Ready for Export' },
      { value: 'PAID', text: 'Paid' },
      { value: 'RECD', text: 'Received' },
      { value: 'REJECT', text: 'Rejected' },
      { value: 'WAIT', text: 'Waiting for Approval' },
    ];
  }

  getMonths() {
    return [
      { title: 'JAN', id: "01", idx: 0, days: 31 }, { title: 'FEB', id: "02", idx: 1, days: 28 }, { title: 'MAR', id: "03", idx: 2, days: 31 },
      { title: 'APR', id: "04", idx: 3, days: 30 }, { title: 'MAY', id: "05", idx: 4, days: 31 }, { title: 'JUN', id: "06", idx: 5, days: 30 },
      { title: 'JUL', id: "07", idx: 6, days: 31 }, { title: 'AUG', id: "08", idx: 7, days: 31 }, { title: 'SEP', id: "09", idx: 8, days: 30 },
      { title: 'OCT', id: "10", idx: 9, days: 31 }, { title: 'NOV', id: "11", idx: 10, days: 30 }, { title: 'DEC', id: "12", idx: 11, days: 31 }
    ];
  }

  getYears() {
    return [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032];
  }

  getMonthsDict() {
    return {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      '10': 'October',
      '11': 'November',
      '12': 'December'
    };
  }

  getWeekDays() {
    const weekdays: any = [];
    let day = dayjs().startOf('week').add(1, 'day');
    while (day.day() < 7 && weekdays.length < 7) {
      weekdays.push({ day: day.format('ddd').toUpperCase(), date: day });
      day = clone(day).add(1, 'day');
    }
    return weekdays;
  }

  getDays(weekStart: any): any {
    const days1 = [{ id: 0, display: 'SUN' }, { id: 1, display: 'MON' }, { id: 2, display: 'TU' }, { id: 3, display: 'WED' }, { id: 4, display: 'THU' }, { id: 5, display: 'FRI' }, { id: 6, display: 'SAT' }]
    const days2 = [{ id: 1, display: 'MON' }, { id: 2, display: 'TU' }, { id: 3, display: 'WED' }, { id: 4, display: 'THU' }, { id: 5, display: 'FRI' }, { id: 6, display: 'SAT' }, { id: 0, display: 'SUN' }]
    return weekStart === 'SUN' ? days1 : days2;
  }

  getNDISRegions(): any {
    return [
      { name: 'NSW', color: "blue" },
      { name: 'QLD', color: "purple" },
      { name: 'SA', color: "orange" },
      { name: 'VIC', color: "red" },
      { name: 'TAS', color: "green" },
      { name: 'NT', color: "brown" },
      { name: 'WA', color: "yellow" },
      { name: 'ACT', color: "navy" },
    ];
    //  'VIC', 'QLD', 'SA', 'TAS', 'ACT', 'NT', 'WA'];
  }

  getNDISRegionsNames() {
    return flatMap(this.getNDISRegions(), (i: any) => i.name);
  }

  getEmploymentTypes(): any {
    return [
      { code: 'FT', name: "Full Time" },
      { code: 'PT', name: "Part Time" },
      // { code: 'PPT', name: "Permanent Part Time" },
      { code: 'CAS', name: "Casual" },
      { code: 'SUB', name: "Subcontractor" },
    ];
  }

  getCosSupportLevels() {
    return ['1', '2', '3'];
  }

  getCOSSupportCode(level: any) {
    const code = level == 2 ? '07_002_0106_8_3' : level == 3 ? '07_004_0132_8_3' : level == 1 ? '07_001_0106_8_3' : '07_002_0106_8_3';
    return code;
  }

  // getManagedTypes() {
  //   return ['Agency Managed (Direct Billing)', 'Plan Managed', 'Self Managed'];
  // }

  getManagedTypesObj() {
    return [
      { code: 'AM', name: 'Agency Managed' }, // (Direct Billing)'},
      { code: 'PM', name: 'Plan Managed' },
      { code: 'SM', name: 'Self Managed' },
    ]
  }

  getCleaningItems() {
    return [
      { 'id': 1, 'code': 'CLEAN:DOM WEEK', 'title': 'Cleaning: Domestic - Weekday', 'rate': 65 },
      { 'id': 2, 'code': 'CLEAN:DOM SAT', 'title': 'Cleaning: Domestic - Saturday', 'rate': 85 },
      { 'id': 3, 'code': 'CLEAN:DOM SUN', 'title': 'Cleaning: Domestic - Sunday', 'rate': 100 },
      { 'id': 4, 'code': 'CLEAN:DOM PUBH', 'title': 'Cleaning: Domestic - Public Holiday', 'rate': 130 },

      { 'id': 5, 'code': 'CLEAN:SPRING WEEK', 'title': 'Cleaning: Spring - Weekday', 'rate': 65 },
      { 'id': 6, 'code': 'CLEAN:SPRING SAT', 'title': 'Cleaning: Spring - Saturday', 'rate': 85 },
      { 'id': 7, 'code': 'CLEAN:SPRING SUN', 'title': 'Cleaning: Spring - Sunday', 'rate': 100 },
      { 'id': 8, 'code': 'CLEAN:SPRING PUBH', 'title': 'Cleaning: Spring - Public Holiday', 'rate': 130 },

      { 'id': 9, 'code': 'CLEAN:WINDOWS WEEK', 'title': 'Cleaning: Windows - Weekday', 'rate': 65 },
      { 'id': 10, 'code': 'CLEAN:WINDOWS SAT', 'title': 'Cleaning: Windows - Saturday', 'rate': 85 },
      { 'id': 11, 'code': 'CLEAN:WINDOWS SUN', 'title': 'Cleaning: Windows - Sunday', 'rate': 100 },

      { 'id': 19, 'code': 'MILEAGE', 'title': 'Mileage per kms', 'rate': 1 },

      { 'id': 20, 'code': 'CLEAN:COMMERCIAL WEEK', 'title': 'Cleaning: Commercial - Weekday', 'rate': 65 },
      { 'id': 21, 'code': 'CLEAN:COMMERCIAL SAT', 'title': 'Cleaning: Commercial - Saturday', 'rate': 85 },
      { 'id': 22, 'code': 'CLEAN:COMMERCIAL SUN', 'title': 'Cleaning: Commercial - Sunday', 'rate': 100 },
      { 'id': 41, 'code': 'CLEAN:COMMERCIAL EVENING', 'title': 'Cleaning: Commercial - Evening', 'rate': 85 },
      { 'id': 42, 'code': 'CLEAN:COMMERCIAL PUBH', 'title': 'Cleaning: Commercial - Public Holiday', 'rate': 150 },
    ];
  }

  getHomecareItems() {
    return [
      { 'id': 12, 'code': 'HOMECARE:DAY WEEK', 'title': 'Homecare: Daytime - Weekday', 'rate': 65 },
      { 'id': 13, 'code': 'HOMECARE:DAY SAT', 'title': 'Homecare: Daytime - Saturday', 'rate': 85 },
      { 'id': 14, 'code': 'HOMECARE:DAY SUN', 'title': 'Homecare: Daytime - Sunday', 'rate': 100 },
      { 'id': 15, 'code': 'HOMECARE:DAY PUBH', 'title': 'Homecare: Daytime - Public Holiday', 'rate': 110 },

      { 'id': 16, 'code': 'HOMECARE:EVE WEEK', 'title': 'Homecare: Evening - Weekday', 'rate': 75 },
      { 'id': 17, 'code': 'HOMECARE:EVE SAT', 'title': 'Homecare: Evening - Saturday', 'rate': 85 },
      { 'id': 18, 'code': 'HOMECARE:EVE SUN', 'title': 'Homecare: Evening - Sunday', 'rate': 100 },
      { 'id': 23, 'code': 'HOMECARE:EVE PUBH', 'title': 'Homecare: Evening - Public Holiday', 'rate': 110 },

      // { 'id': 32, 'code': 'HOMECARE SPECIAL:DAY WEEK', 'title': 'Homecare Special: Daytime - Weekday', 'rate': 42 },
      // { 'id': 33, 'code': 'HOMECARE SPECIAL:DAY SAT', 'title': 'Homecare Special: Daytime - Saturday', 'rate': 50 },

      { 'id': 30, 'code': 'CARECONNECT:DAY WEEK', 'title': 'Homecare: Daytime - Weekday (Care Connect)', 'rate': 60 },

      { 'id': 19, 'code': 'MILEAGE', 'title': 'Mileage per kms', 'rate': 1 },

      { 'id': 24, 'code': 'CONCIERGE:DAY WEEK', 'title': 'Concierge: Daytime - Weekday', 'rate': 65 },
      { 'id': 54, 'code': 'CONCIERGE:DAY SAT', 'title': 'Concierge: Daytime - Saturday', 'rate': 85 },
      { 'id': 55, 'code': 'CONCIERGE:DAY SUN', 'title': 'Concierge: Daytime - Sunday', 'rate': 100 },
      { 'id': 56, 'code': 'CONCIERGE:DAY PUBH', 'title': 'Concierge: Daytime - Public Holiday', 'rate': 110 },

      { 'id': 57, 'code': 'CONCIERGE:EVE WEEK', 'title': 'Concierge: Evening - Weekday', 'rate': 75 },
      { 'id': 58, 'code': 'CONCIERGE:EVE SAT', 'title': 'Concierge: Evening - Saturday', 'rate': 85 },
      { 'id': 59, 'code': 'CONCIERGE:EVE SUN', 'title': 'Concierge: Evening - Sunday', 'rate': 100 },
      { 'id': 60, 'code': 'CONCIERGE:EVE PUBH', 'title': 'Concierge: Evening - Public Holiday', 'rate': 115 },
    ];
  }

  getGardeningItems() {
    return [
      { 'id': 27, 'code': 'GARDENING:DAY WEEK', 'title': 'Gardening: Daytime - Weekday', 'rate': 55 },
      { 'id': 61, 'code': 'GARDENING:DAY SAT', 'title': 'Gardening: Daytime - Saturday', 'rate': 90 },
      { 'id': 62, 'code': 'GARDENING:DAY SUN', 'title': 'Gardening: Daytime - Sunday', 'rate': 120 },
      { 'id': 63, 'code': 'GARDENING:DAY PUBH', 'title': 'Gardening: Daytime - Public Holiday', 'rate': 150 },

      { 'id': 19, 'code': 'MILEAGE', 'title': 'Mileage per kms', 'rate': 1 },
    ];
  }

  getLicensedBuildingItems() {
    return [
      { 'id': 28, 'code': 'LICENSED BUILDING: WEEK', 'title': 'Licensed Building: Daytime - Weekday', 'rate': 50 },
    ];
  }

  getMedicareItems() {
    return [
      { 'id': 80, 'code': 'OT:DAY45', 'title': 'Occupational Therapy Services: Daytime - 45 mins', 'rate': 130 },
      { 'id': 81, 'code': 'ST:DAY45', 'title': 'Speech Therapy: Daytime - 45 mins', 'rate': 130 },
      { 'id': 82, 'code': 'OT:REPORT', 'title': 'Occupational Therapy Report', 'rate': 400 },
      { 'id': 83, 'code': 'ST:REPORT', 'title': 'Speech Therapy Report', 'rate': 400 },

      { 'id': 19, 'code': 'MILEAGE', 'title': 'Mileage per kms', 'rate': 1 },
    ];
  }

  getNoteTypes() {
    return [
      { id: 1, name: 'Phone Call', color: 'red', group: 'default' },
      { id: 2, name: 'Email', group: 'default' },
      { id: 3, name: 'Intake', group: 'other' },
      { id: 4, name: 'Session Outcomes', group: 'therapy' },
      { id: 5, name: 'Reports', group: 'default' },
      { id: 6, name: 'Financials', group: 'other' },
      { id: 7, name: 'Plan Management', group: 'other' },
      { id: 8, name: 'Text Message', group: 'default' },
      { id: 9, name: 'Research', group: 'therapy' },
      { id: 12, name: 'Meeting', group: 'default' },
      { id: 13, name: 'Alert', color: 'red', group: 'default' },
      // { id: 10, name: 'Job'}
    ]
  }

  getAlertTypes() {
    return [
      { id: 1, name: 'Billing' },
      { id: 2, name: 'Roster' },
      { id: 3, name: 'Health' },     
      { id: 4, name: 'Other' },
    ]
  }

  getNoteManagedTypes() {
    return [
      { id: 1, name: 'COS' },
      { id: 4, name: 'PM' },
      { id: 2, name: 'Therapy' },
      { id: 3, name: 'Other' },
    ]
  }

  getBillableStatuses(): any {
    return [{ id: 0, status: 'None', value: false }, { id: 1, status: 'To Be Billed', value: true }]
  }

  getRosterColors() {
    return [
      { name: 'Holiday Cleaning', color: 'orange' },
      { name: 'Commercial Cleaning', color: 'black' },
      { name: 'Domestic Cleaning', color: '#6699FF' },
      { name: 'NDIS: Cleaning', color: 'purple' },
      { name: 'O65: Home Care', color: 'silver' }
    ];
  }

  getFileCategories() {
    return [
      { name: 'Uncategorized', value: 'uncategorized', color: 'dark' },
      { name: 'PM Invoices', value: 'pm-invoices', color: 'pink' },
      { name: 'Service Agreements', value: 'service-agrements', color: 'orange' },
      { name: 'NDIS Plans', value: 'ndis-plans', color: 'black' },
      { name: 'Price Guides', value: 'price-guides', color: 'brown' },
      { name: 'Receipts', value: 'receipts', color: 'red' },
      { name: 'Client Notes', value: 'notes-for-client', color: 'purple' },
      { name: 'Timesheets', value: 'timesheets', color: 'yellow' },
      { name: 'Remittances', value: 'remittances', color: 'green' },
      { name: 'Staff Profile Docs', value: 'profile-docs', color: 'blue' }
    ];
  }

  getFileTypes() {
    return [
      { name: 'PDF File', value: 'pdf', contentType: 'application/pdf' },
      { name: 'Excel Document', value: 'xlsx', contentType: 'application/vnd.ms-excel' },
      { name: 'Image (PNG)', value: 'png', contentType: 'image/png' },
      { name: 'Image (JPG)', value: 'jpeg', contentType: 'image/jpeg' },
      { name: 'Word Document', value: 'docx', contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
      { name: 'CSV Document', value: 'csv', contentType: 'text/csv' }
    ];
  }

  getImprovementCategories() {
    return [
      { id: 'SD', name: 'Service Delivery Improvement' },
      { id: 'PI', name: 'Process Improvement' },
      { id: 'SI', name: 'System Improvement' },
      { id: 'TD', name: 'Training and Development' },
      { id: 'SE', name: 'Stakeholder Engagement' },
      { id: 'QI', name: 'Quality Improvement' },
      { id: 'CI', name: 'Communication Improvement' },
      { id: 'PPE', name: 'Policy and Procedure Engagement' },
      { id: 'OD', name: 'Organizational Development' }
    ]
  }

  getImprovementSources() {
    return [
      { id: 'PF', name: 'Participant feedback' },
      { id: 'SO', name: 'Staff observation' },
      { id: 'IA', name: 'Internal audit' },
      { id: 'EA', name: 'External audit' },
      { id: 'C', name: 'Complaints' },
      { id: 'IR', name: 'Incident reports' },
      { id: 'RA', name: 'Risk assessments' },
      { id: 'RR', name: 'Regulatory requirements' },
      { id: 'MR', name: 'Management reviews' }
    ]
  }

  getPracticeStandards() {
    return [
      { id: 'C', name: 'Core Module' },
      { id: '4.3', name: 'Module; 4.3 - Supports and Services' },
      { id: '4.5', name: 'Module; 4.5 - Service Access and Engagement' },
      { id: '2a', name: 'Module; 2a - ' },
      { id: '3', name: 'Module; 3 - Provision of Supports:' },
      { id: '4', name: 'Module; 4 - Empowerment and Choice' }
    ]
  }

  getFundingSources() {
    return ['Client', 'NDIS', 'Insurance', 'Medicare', 'Other'];
  }

  getFundingManagement() {
    return ['NDIA', 'Participant', 'Participant\'s Nominee', 'Plan Manager'];
  }

  getManagementStyles() {
    return ['N/A', 'Agency', 'Plan Management', 'Self Managed'];
  }

  getServiceAgreementTypes() {
    return ['Signed Agreement', 'Verbal Agreement', 'Email', 'Other'];
  };

  mapStartDay(day: any, isSunday0: any): number {
    let ret = 1;
    switch (day) {
      case 'MON': { ret = 1; break; }
      case 'TUE': { ret = 2; break; }
      case 'WED': { ret = 3; break; }
      case 'THU': { ret = 4; break; }
      case 'FRI': { ret = 5; break; }
      case 'SAT': { ret = 6; break; }
      case 'SUN': { ret = isSunday0 ? 0 : 7; break; }
    }
    return ret;
  }

  getGroupClientRatios(): any {
    return [
      { id: 1, value: '1:1' },
      { id: 2, value: '1:2' },
      { id: 3, value: '1:3' },
      { id: 4, value: '1:4' },
      { id: 5, value: '1:5' },
      { id: 6, value: '1:6' }
    ];
  }

  getGroupClientGroupings(): any {
    return [
      { id: 1, name: 'A' },
      { id: 2, name: 'B' },
      { id: 3, name: 'C' },
      { id: 4, name: 'D' },
      { id: 5, name: 'E' },
      { id: 6, name: 'F' }
    ];
  }

  getGroupNameById = (id: any) => this.getGroupClientGroupings().find(g => g.id === id)?.name;

  dateLessThanComparator(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: `${from} should be before ${to}`
        };
      }
      return {};
    };
  }

  mimeDb(search: string) {

    const mimes =
    {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
        "source": "iana",
        "compressible": false,
        "extensions": ["docx"]
      },
      "application/msword": {
        "source": "iana",
        "compressible": false,
        "extensions": ["doc", "dot"]
      },
      "application/pdf": {
        "source": "iana",
        "compressible": false,
        "extensions": ["pdf"]
      },
      "image/jpeg": {
        "source": "iana",
        "compressible": false,
        "extensions": ["jpeg", "jpg", "jpe"]
      },
      "image/png": {
        "source": "iana",
        "compressible": false,
        "extensions": ["png"]
      },
      "text/csv": {
        "source": "iana",
        "compressible": true,
        "extensions": ["csv"]
      },
      "application/vnd.ms-excel": {
        "source": "iana",
        "compressible": false,
        "extensions": ["xls", "xlm", "xla", "xlc", "xlt", "xlw"]
      },
      "text/xml": {
        "source": "iana",
        "compressible": true,
        "extensions": ["xml"]
      },
      "application/octet-stream": {
        "source": "iana",
        "compressible": false,
        "extensions": ["bin", "dms", "lrf", "mar", "so", "dist", "distz", "pkg", "bpk", "dump", "elc", "deploy", "exe", "dll", "deb", "dmg", "iso", "img", "msi", "msp", "msm", "buffer"]
      },
    }

    const match = find(mimes, (m, key) => key === search);
    return match;

  }

  getContentTypes() {
    return [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/pdf",
      "image/jpeg",
      "image/png",
      "text/csv",
      "application/vnd.ms-excel",
      "text/xml",
      "application/octet-stream",
    ];
  }

  getDocumentType(docTypeString: any): any {
    if (docTypeString) {
      if (docTypeString.indexOf('word') > -1 || docTypeString.indexOf('msword') > -1) { return 'document'; }
      if (docTypeString.indexOf('spreadsheet') > -1 || docTypeString.indexOf('excel') > -1) { return 'spreadsheet'; }
    }
    return docTypeString;
  }

  getTimeZone(state: any) {
    let tz = ''
    if (state) {
      switch (state) {
        case 'QLD':
          tz = 'Australia, Brisbane'; break;
        case 'NSW':
          tz = 'Australia, New South Wales'; break;
        default:
          tz = 'Australia, Brisbane'; break;
      }
    }
    return tz;
  }

  getClientIntakeNoticeMethods() {
    return [
      { id: 'email', name: 'Email' },
      { id: 'phone', name: 'Phone' },
      { id: 'document', name: 'Document' },
      { id: 'other', name: 'Other' }
    ];
  }

  getClientOuttakeNoticeMethods() {
    return [
      { id: 'email', name: 'Email' },
      { id: 'phone', name: 'Phone' },
      { id: 'document', name: 'Document' },
      { id: 'other', name: 'Other' }
    ];
  }

  calcLineItemTotal(invoice: any) {
    const quantityItems = filter(invoice.lineItems, li => li.unit == 'QTY' || !li.unit);
    const quanityTotal = sumBy(quantityItems, (li: any) => li.quantity * li.rate);
    const hoursItems = filter(invoice.lineItems, li => li.unit == 'HOUR');
    const hoursTotal = sumBy(hoursItems, (li: any) => this.convertHoursToMinute(li.hours) / 60 * li.rate);

    return round(quanityTotal + hoursTotal, 2);
  }


  convertHoursToMinute(str) {
    if (str) {
      let [hours, minutes] = str.split(':');
      return (+hours * 60) + (+minutes);
    } else {
      return null
    }
  }

  getTabsForRegistryClass(registryClass: any) {
    let resultSet = [];
    const pendingTab: any = { key: 'pending', title: 'Pending', count: 0 };
    const requestedTab: any = { key: 'requested', title: 'Requested', count: 0 };
    const approvedTab: any = { key: 'approved', title: 'Approved', count: 0 };
    const progressTab: any = { key: 'progress', title: 'In Progress', count: 0 };
    const completedTab: any = { key: 'completed', title: 'Completed', count: 0 };
    const implementedTab: any = { key: 'implemented', title: 'Implemented', count: 0 };
    const presentedTab: any = { key: 'presented', title: 'Presented', count: 0 };
    const archivedTab: any = { key: 'archived', title: 'Archived', count: 0 };
    const allTab: any = { key: 'all', title: 'All', count: 0 };

    const defaultTabList = [pendingTab, approvedTab, progressTab, completedTab, implementedTab, archivedTab, allTab];

    switch (registryClass) {
      case 'FBK':
        resultSet = defaultTabList;
        break;
      case 'RSK':
        resultSet = defaultTabList;
        break;
      case 'INC':
        resultSet = defaultTabList;
        break;
      case 'TRN':
        resultSet = [requestedTab, approvedTab, progressTab, completedTab, presentedTab, archivedTab, allTab];
        break;
      case 'HAZ':
        resultSet = defaultTabList;
        break;
      case 'INT':
        resultSet = defaultTabList;
        break;
      case 'POL':
        resultSet = defaultTabList;
        break;
      case 'COM':
        resultSet = defaultTabList;
        break;  
      default:
        resultSet = defaultTabList;  
    }

    return resultSet;
  }

  getFirstItem(items: any) {
    return first(items);
  }

  getLastItem(items: any) {
    return last(items);
  }

  makeDateFormat(day) {
    return dayjs(day).format('YYYY-MM-DD')
  }

}
